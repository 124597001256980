import { Box, Drawer, useMediaQuery, useTheme } from "@mui/material";

import { drawerWidth } from "../../styles/theme";

import DrawerContents from "./DrawerContents";

interface Props {
  drawerOpen: boolean;

  drawerToggle(): void;
}

export default function Sidebar({ drawerOpen, drawerToggle }: Props) {
  const theme = useTheme();
  const matchUpMd = useMediaQuery(theme.breakpoints.up("md"));

  const container = typeof window !== "undefined" ? () => window.document.body : undefined;

  return (
    <Box component="nav" sx={{ flexShrink: { md: 0 }, width: matchUpMd ? drawerWidth : "auto" }} aria-label="mailbox folders">
      <Drawer
        variant={matchUpMd ? "persistent" : "temporary"}
        anchor="left"
        open={drawerOpen}
        onClose={drawerToggle}
        sx={{
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            background: theme.palette.background.default,
            color: theme.palette.text.primary,
            borderRight: "none",
            [theme.breakpoints.up("md")]: {
              top: "88px",
            },
          },
        }}
        ModalProps={{ container, keepMounted: true }}
        color="inherit"
      >
        <DrawerContents />
      </Drawer>
    </Box>
  );
}
