import { Box } from "@mui/material";

import LogoSection from "./LogoSection";
import MenuCard from "./MenuCard";
import MenuList from "./MenuList";

export default function DrawerContents() {
  return (
    <>
      <Box sx={{ display: { xs: "block", md: "none" } }}>
        <Box sx={{ display: "flex", p: 2, mx: "auto" }}>
          <LogoSection />
        </Box>
      </Box>
      <Box sx={{ px: 2 }}>
        <MenuList />
        <MenuCard />
      </Box>
    </>
  );
}
