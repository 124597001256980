import { Divider, List, Typography } from "@mui/material";

import { MenuItem } from "../../../state/menu";
import * as colors from "../../../styles/colors";

import NavCollapse from "./NavCollapse";
import NavItem from "./NavItem";

// ==============================|| SIDEBAR MENU LIST GROUP ||============================== //

export default function NavGroup({ item }: { item: MenuItem }) {
  // menu list collapse & items
  const items = item.children?.map((menu) => {
    switch (menu.type) {
      case "collapse":
        return <NavCollapse key={menu.id} menu={menu} level={1} />;
      case "item":
        return <NavItem key={menu.id} item={menu} level={1} />;
      default:
        return (
          <Typography key={menu.id} variant="h6" color="error" align="center">
            Menu Items Error
          </Typography>
        );
    }
  });

  return (
    <>
      <List
        subheader={
          item.title && (
            <Typography
              variant="caption"
              sx={{ fontSize: "0.875rem", fontWeight: 500, color: colors.grey900, padding: "6px", textTransform: "capitalize", marginTop: "10px" }}
              display="block"
              gutterBottom
            >
              {item.title}
              {item.caption && (
                <Typography
                  variant="caption"
                  sx={{ fontSize: "0.6875rem", fontWeight: 500, color: colors.grey500, textTransform: "capitalize" }}
                  display="block"
                  gutterBottom
                >
                  {item.caption}
                </Typography>
              )}
            </Typography>
          )
        }
      >
        {items}
      </List>

      {/* group divider */}
      <Divider sx={{ mt: 0.25, mb: 1.25 }} />
    </>
  );
}
