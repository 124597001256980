import { forwardRef, ReactNode, useMemo } from "react";

import { Box, Collapse, Fade, Grow, Slide, SxProps, Zoom } from "@mui/material";

interface Props {
  children: ReactNode;
  type?: "grow" | "fade" | "collapse" | "slide" | "zoom";
  position?: "top-left" | "top-right" | "top" | "bottom-left" | "bottom-right" | "bottom";
  direction?: "up" | "down" | "left" | "right";
  sx?: SxProps;
}

export default forwardRef<typeof Box, Props>(function Transitions(
  { children, position = "top-left", type = "grow", direction = "up", sx, ...others }: Props,
  ref,
) {
  const positionSX = useMemo(() => {
    switch (position) {
      case "top-right":
        return { ...sx, transformOrigin: "top right" };
      case "top":
        return { ...sx, transformOrigin: "top" };
      case "bottom-left":
        return { ...sx, transformOrigin: "bottom left" };
      case "bottom-right":
        return { ...sx, transformOrigin: "bottom right" };
      case "bottom":
        return { ...sx, transformOrigin: "bottom" };
      case "top-left":
      default:
        return { ...sx, transformOrigin: "0 0 0" };
    }
  }, [position, sx]);

  return (
    <Box ref={ref}>
      {type === "grow" && (
        <Grow {...others}>
          <Box sx={positionSX}>{children}</Box>
        </Grow>
      )}
      {type === "collapse" && (
        <Collapse {...others} sx={positionSX}>
          {children}
        </Collapse>
      )}
      {type === "fade" && (
        <Fade {...others} timeout={{ appear: 500, enter: 600, exit: 400 }}>
          <Box sx={positionSX}>{children}</Box>
        </Fade>
      )}
      {type === "slide" && (
        <Slide
          {...others}
          timeout={{
            appear: 0,
            enter: 400,
            exit: 200,
          }}
          direction={direction}
        >
          <Box sx={positionSX}>{children}</Box>
        </Slide>
      )}
      {type === "zoom" && (
        <Zoom {...others}>
          <Box sx={positionSX}>{children}</Box>
        </Zoom>
      )}
    </Box>
  );
});
