import { ChangeEvent, useCallback } from "react";

import { Clear, Search, Tune } from "@mui/icons-material";
import { Avatar, Box, ButtonBase, InputAdornment } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import { bindToggle, PopupState } from "material-ui-popup-state/hooks";

import * as colors from "../../../styles/colors";

import { HeaderAvatarStyle, OutlineInputStyle } from "./common";

interface Props {
  value: string;

  setValue(value: string): void;

  popupState: PopupState;
}

export function MobileSearch({ value, setValue, popupState }: Props) {
  const theme = useTheme();

  const handleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => setValue(e.target.value), [setValue]);

  return (
    <OutlineInputStyle
      id="input-search-header"
      value={value}
      onChange={handleChange}
      placeholder="Search"
      startAdornment={
        <InputAdornment position="start">
          <Search sx={{ stroke: 1.5, size: "1rem", color: theme.palette.grey[500] }} />
        </InputAdornment>
      }
      endAdornment={
        <InputAdornment position="end">
          <ButtonBase sx={{ borderRadius: "12px" }}>
            <HeaderAvatarStyle variant="rounded">
              <Tune sx={{ stroke: 1.5, size: "1.3rem" }} />
            </HeaderAvatarStyle>
          </ButtonBase>
          <Box sx={{ ml: 2 }}>
            <ButtonBase sx={{ borderRadius: "12px" }}>
              <Avatar
                variant="rounded"
                sx={{
                  cursor: "pointer",
                  borderRadius: "8px",
                  width: "34px",
                  height: "34px",
                  fontSize: "1.2rem",
                  background: colors.orangeLight,
                  color: colors.orangeDark,
                  "&:hover": {
                    background: colors.orangeDark,
                    color: colors.orangeLight,
                  },
                }}
                {...bindToggle(popupState)}
              >
                <Clear sx={{ stroke: 1.5, size: "1.3rem" }} />
              </Avatar>
            </ButtonBase>
          </Box>
        </InputAdornment>
      }
      aria-describedby="search-helper-text"
      inputProps={{ "aria-label": "weight" }}
    />
  );
}
