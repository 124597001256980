import TableChartOutlinedIcon from "@mui/icons-material/TableChartOutlined";
import { Avatar, Card, CardContent, List, ListItem, ListItemAvatar, ListItemText, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

import * as colors from "../../styles/colors";

import LinearProgressWithLabel from "./LinearProgressWithLabel";

export default function MenuCard() {
  return (
    <StyledCard>
      <CardContent sx={{ p: 2 }}>
        <List sx={{ p: 0, m: 0 }}>
          <ListItem alignItems="flex-start" disableGutters sx={{ p: 0 }}>
            <ListItemAvatar sx={{ mt: 0 }}>
              <StyledAvatar variant="rounded">
                <TableChartOutlinedIcon fontSize="inherit" />
              </StyledAvatar>
            </ListItemAvatar>
            <ListItemText
              sx={{ mt: 0 }}
              primary={
                <Typography variant="subtitle1" color={colors.primary800}>
                  Certificados
                </Typography>
              }
              secondary={<Typography variant="caption">0.000 ativos</Typography>}
            />
          </ListItem>
        </List>
        <LinearProgressWithLabel value={5} label="Expirados" />
      </CardContent>
    </StyledCard>
  );
}

const StyledCard = styled(Card)(({ theme }) => ({
  background: theme.palette.primary.light,
  marginBottom: "22px",
  overflow: "hidden",
  position: "relative",
  "&:after": {
    content: '""',
    position: "absolute",
    width: "157px",
    height: "157px",
    background: colors.primary200,
    borderRadius: "50%",
    top: "-105px",
    right: "-96px",
  },
}));

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  cursor: "pointer",
  borderRadius: "8px",
  width: "44px",
  height: "44px",
  fontSize: "1.5rem",
  color: theme.palette.primary.main,
  border: "none",
  borderColor: theme.palette.primary.main,
  background: "#fff",
  marginRight: "12px",
}));
