import { ReactNode } from "react";

import { Grid, LinearProgress, linearProgressClasses, LinearProgressProps, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

import * as colors from "../../styles/colors";

export default function LinearProgressWithLabel({ value, label, ...others }: LinearProgressProps & { label: ReactNode }) {
  return (
    <Grid container direction="column" spacing={1} sx={{ mt: 1.5 }}>
      <Grid item>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography variant="h6" sx={{ color: colors.primary800 }}>
              {label}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="h6" color="inherit">{`${Math.round(value ?? 0)}%`}</Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <LinearProgressWithBorder variant="determinate" value={value} {...others} />
      </Grid>
    </Grid>
  );
}

const LinearProgressWithBorder = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 30,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "#fff",
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.primary.main,
  },
}));
