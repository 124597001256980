import { Apps, Dashboard, Rocket, RocketLaunch, SvgIconComponent, VerifiedUser, WorkspacePremium } from "@mui/icons-material";
import { ChipProps } from "@mui/material";

export interface MenuItem {
  id: string;
  title: string;
  type: "group" | "collapse" | "item";
  caption?: string;
  url?: string;
  icon?: SvgIconComponent;
  breadcrumbs?: boolean;
  target?: boolean;
  disabled?: boolean;
  external?: boolean;
  chip?: ChipProps;
  children?: MenuItem[];
}

const items: MenuItem[] = [
  {
    id: "dashboard",
    title: "Dashboard",
    type: "group",
    children: [{ id: "default", title: "Dashboard", type: "item", url: "/dashboard", icon: Dashboard }],
  },
  {
    id: "cadastros",
    title: "Cadastros",
    type: "group",
    children: [
      { id: "cadastros-acoes", title: "Ações", type: "item", icon: Rocket, url: "/admin/acoes" },
      { id: "cadastros-aplicacoes", title: "Aplicações", type: "item", icon: Apps, url: "/admin/aplicacoes" },
      { id: "cadastros-certificados", title: "Certificados", type: "item", icon: WorkspacePremium, url: "/admin/certificados" },
    ],
  },
  {
    id: "logs",
    title: "Logs",
    type: "group",
    children: [
      { id: "logs-acoes", title: "De Ações", type: "item", url: "/logs/acoes", icon: RocketLaunch },
      { id: "logs-cadastros", title: "De Cadastros", type: "item", url: "/logs/cadastros", icon: VerifiedUser },
    ],
  },
];

export default items;
