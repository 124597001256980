import NextLink from "next/link";

import { ButtonBase } from "@mui/material";

import Logo from "./Logo";

export default function LogoSection() {
  return (
    <ButtonBase component={NextLink} href="/" disableRipple>
      <Logo />
    </ButtonBase>
  );
}
