import { Avatar, OutlinedInput } from "@mui/material";
import { styled } from "@mui/material/styles";

export const OutlineInputStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 434,
  marginLeft: 16,
  paddingLeft: 16,
  paddingRight: 16,
  "& input": {
    background: "transparent !important",
    paddingLeft: "4px !important",
  },
  [theme.breakpoints.down("lg")]: {
    width: 250,
  },
  [theme.breakpoints.down("md")]: {
    width: "100%",
    marginLeft: 4,
    background: "#fff",
  },
}));

export const HeaderAvatarStyle = styled(Avatar)(({ theme }) => ({
  cursor: "pointer",
  borderRadius: "8px",
  width: "34px",
  height: "34px",
  fontSize: "1.2rem",
  background: theme.palette.secondary.light,
  color: theme.palette.secondary.dark,
  "&:hover": {
    background: theme.palette.secondary.dark,
    color: theme.palette.secondary.light,
  },
}));
