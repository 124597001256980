import Image from "next/image";

import { Box, Typography } from "@mui/material";

export default function Logo() {
  return (
    <Box sx={{ display: "flex", alignItems: "center", p: 1 }}>
      <Image src="/favicon.png" width={32} height={32} alt="Consyst-e" />
      <Typography variant="h3" sx={{ mt: 1, ml: 1.5 }}>
        GCert
      </Typography>
    </Box>
  );
}
