import NextLink from "next/link";
import { useCallback, useMemo } from "react";

import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { Avatar, Chip, ListItemButton, ListItemIcon, ListItemText, Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import { useAtom } from "jotai";

import { drawerState } from "../../../state/customization";
import { MenuItem } from "../../../state/menu";
import * as colors from "../../../styles/colors";
import { borderRadius } from "../../../styles/theme";

// ==============================|| SIDEBAR MENU LIST ITEMS ||============================== //

export default function NavItem({ item, level }: { item: MenuItem; level: number }) {
  const theme = useTheme();
  const matchesSM = useMediaQuery(theme.breakpoints.down("lg"));
  const [, setDrawer] = useAtom(drawerState);

  const isOpen = useMemo(() => Boolean(item.url && item.url !== "/" && document.location.pathname.startsWith(item.url)), [item.url]);

  const itemIcon = useMemo(() => {
    const Icon = item.icon;
    return Icon ? (
      <Icon sx={{ stroke: 1.5, size: "1.3rem" }} />
    ) : (
      <FiberManualRecordIcon sx={{ width: isOpen ? 8 : 6, height: isOpen ? 8 : 6 }} fontSize={level > 0 ? "inherit" : "medium"} />
    );
  }, [isOpen, item.icon, level]);

  const handleClick = useCallback(() => {
    if (matchesSM) setDrawer(false);
  }, [matchesSM, setDrawer]);

  const linkProps = !item.url ? {} : { component: NextLink, href: item.url, target: item.target ? "_blank" : "_self" };

  return (
    <ListItemButton
      disabled={item.disabled}
      sx={{
        borderRadius: `${borderRadius}px`,
        mb: 0.5,
        alignItems: "flex-start",
        backgroundColor: level > 1 ? "transparent !important" : "inherit",
        py: level > 1 ? 1 : 1.25,
        pl: `${level * 24}px`,
      }}
      selected={isOpen}
      onClick={handleClick}
      {...linkProps}
    >
      <ListItemIcon sx={{ my: "auto", minWidth: !item?.icon ? 18 : 36 }}>{itemIcon}</ListItemIcon>
      <ListItemText
        primary={
          <Typography variant={isOpen ? "h5" : "body1"} color="inherit">
            {item.title}
          </Typography>
        }
        secondary={
          item.caption && (
            <Typography
              variant="caption"
              sx={{ fontSize: "0.6875rem", fontWeight: 500, color: colors.darkTextSecondary, textTransform: "capitalize" }}
              display="block"
              gutterBottom
            >
              {item.caption}
            </Typography>
          )
        }
      />
      {item.chip && (
        <Chip
          color={item.chip.color}
          variant={item.chip.variant}
          size={item.chip.size}
          label={item.chip.label}
          avatar={item.chip.avatar && <Avatar>{item.chip.avatar}</Avatar>}
        />
      )}
    </ListItemButton>
  );
}
