import { useCallback, useState } from "react";

import { FiberManualRecord as FiberManualRecordIcon, ExpandMore as IconChevronDown, ExpandLess as IconChevronUp } from "@mui/icons-material";
import { Collapse, List, ListItemButton, ListItemIcon, ListItemText, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import { MenuItem } from "../../../state/menu";
import * as colors from "../../../styles/colors";
import { borderRadius } from "../../../styles/theme";

import NavItem from "./NavItem";

// ==============================|| SIDEBAR MENU LIST COLLAPSE ITEMS ||============================== //

interface Props {
  menu: MenuItem;
  level: number;
}

export default function NavCollapse({ menu, level }: Props) {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState<string | null>(null);

  const handleClick = useCallback(() => {
    setOpen((o) => !o);
    setSelected(!selected ? menu.id : null);
  }, [menu.id, selected]);

  // menu collapse & item
  const menus = menu.children?.map((item) => {
    switch (item.type) {
      case "collapse":
        return <NavCollapse key={item.id} menu={item} level={level + 1} />;
      case "item":
        return <NavItem key={item.id} item={item} level={level + 1} />;
      default:
        return (
          <Typography key={item.id} variant="h6" color="error" align="center">
            Menu Items Error
          </Typography>
        );
    }
  });

  const Icon = menu.icon;
  const menuIcon = Icon ? (
    <Icon sx={{ strokeWidth: 1.5, size: "1.3rem", marginTop: "auto", marginBottom: "auto" }} />
  ) : (
    <FiberManualRecordIcon
      sx={{
        width: selected === menu.id ? 8 : 6,
        height: selected === menu.id ? 8 : 6,
      }}
      fontSize={level > 0 ? "inherit" : "medium"}
    />
  );

  return (
    <>
      <ListItemButton
        sx={{
          borderRadius: `${borderRadius}px`,
          mb: 0.5,
          alignItems: "flex-start",
          backgroundColor: level > 1 ? "transparent !important" : "inherit",
          py: level > 1 ? 1 : 1.25,
          pl: `${level * 24}px`,
        }}
        selected={selected === menu.id}
        onClick={handleClick}
      >
        <ListItemIcon sx={{ my: "auto", minWidth: !menu.icon ? 18 : 36 }}>{menuIcon}</ListItemIcon>
        <ListItemText
          primary={
            <Typography variant={selected === menu.id ? "h5" : "body1"} color="inherit" sx={{ my: "auto" }}>
              {menu.title}
            </Typography>
          }
          secondary={
            menu.caption && (
              <Typography
                variant="caption"
                sx={{ fontSize: "0.6875rem", fontWeight: 500, color: colors.darkTextSecondary, textTransform: "capitalize" }}
                display="block"
                gutterBottom
              >
                {menu.caption}
              </Typography>
            )
          }
        />
        {open ? (
          <IconChevronUp sx={{ stroke: 1.5, size: "1rem", marginTop: "auto", marginBottom: "auto" }} />
        ) : (
          <IconChevronDown sx={{ stroke: 1.5, size: "1rem", marginTop: "auto", marginBottom: "auto" }} />
        )}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List
          component="div"
          disablePadding
          sx={{
            position: "relative",
            "&:after": {
              content: "''",
              position: "absolute",
              left: "32px",
              top: 0,
              height: "100%",
              width: "1px",
              opacity: 1,
              background: theme.palette.primary.light,
            },
          }}
        >
          {menus}
        </List>
      </Collapse>
    </>
  );
}
