import { forwardRef, ReactNode } from "react";

import { Card, CardContent, CardHeader, Divider, SxProps } from "@mui/material";

import * as colors from "../../styles/colors";

// constant
const headerSX = {
  "& .MuiCardHeader-action": { mr: 0 },
};

// ==============================|| CUSTOM MAIN CARD ||============================== //

interface Props {
  border?: boolean;
  boxShadow?: boolean;
  children?: ReactNode;
  content?: boolean;
  contentClass?: string;
  contentSX?: object;
  secondary?: ReactNode;
  shadow?: string;
  elevation?: number;
  sx?: SxProps;
  title?: ReactNode;
  subtitle?: ReactNode;
}

export default forwardRef<HTMLDivElement, Props>(function MainCard(
  { border = true, boxShadow, children, content = true, contentClass = "", contentSX = {}, secondary, shadow, sx = {}, title, subtitle, ...others },
  ref,
) {
  return (
    <Card
      ref={ref}
      {...others}
      sx={{
        border: border ? "1px solid" : "none",
        borderColor: `${colors.primary200}75`,
        ":hover": {
          boxShadow: boxShadow ? shadow || "0 2px 14px 0 rgb(32 40 45 / 8%)" : "inherit",
        },
        ...sx,
      }}
    >
      {/* card header and action */}
      {title && <CardHeader sx={headerSX} title={title} action={secondary} subheader={subtitle} subheaderTypographyProps={{ mt: 1 }} />}

      {/* content & header divider */}
      {title && <Divider />}

      {/* card content */}
      {content && (
        <CardContent sx={contentSX} className={contentClass}>
          {children}
        </CardContent>
      )}
      {!content && children}
    </Card>
  );
});
