import { ReactNode, useCallback, useEffect } from "react";

import { AppBar, Box, NoSsr, styled, Theme, Toolbar, useMediaQuery, useTheme } from "@mui/material";

import { useAtom, useAtomValue } from "jotai";

import { validCredentialsAtom } from "../../state/auth";
import { drawerState } from "../../state/customization";
import * as colors from "../../styles/colors";
import { borderRadius, drawerWidth } from "../../styles/theme";
import Blocked from "../common/Blocked";

import Header from "./Header";
import Sidebar from "./Sidebar";

export default function MainLayout({ children }: { children: ReactNode }) {
  const theme = useTheme();
  const matchDownMd = useMediaQuery(theme.breakpoints.down("lg"));
  const auth = useAtomValue(validCredentialsAtom);

  // Handle left drawer
  const [leftDrawerOpened, setLeftDrawerOpened] = useAtom(drawerState);
  const handleLeftDrawerToggle = useCallback(() => {
    setLeftDrawerOpened(!leftDrawerOpened);
  }, [leftDrawerOpened, setLeftDrawerOpened]);

  useEffect(() => {
    setLeftDrawerOpened(!matchDownMd);
  }, [matchDownMd, setLeftDrawerOpened]);

  return (
    <Box sx={{ display: "flex" }}>
      <AppBar
        enableColorOnDark
        position="fixed"
        color="inherit"
        elevation={0}
        sx={{
          bgcolor: theme.palette.background.default,
          transition: leftDrawerOpened ? theme.transitions.create("width") : "none",
        }}
      >
        <Toolbar>
          <Header onLeftDrawerToggle={handleLeftDrawerToggle} />
        </Toolbar>
      </AppBar>

      <Sidebar drawerOpen={leftDrawerOpened} drawerToggle={handleLeftDrawerToggle} />

      <Main theme={theme} open={leftDrawerOpened}>
        <NoSsr>{auth ? children : <Blocked />}</NoSsr>
      </Main>
    </Box>
  );
}

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" && prop !== "theme" })(({ theme, open }: { theme: Theme; open: boolean }) => ({
  backgroundColor: colors.primaryLight,
  width: "100%",
  minHeight: "calc(100vh - 88px)",
  flexGrow: 1,
  padding: "20px",
  marginTop: "88px",
  marginRight: "20px",
  borderRadius: `${borderRadius}px`,

  ...(!open && {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    [theme.breakpoints.up("md")]: {
      marginLeft: -(drawerWidth - 20),
      width: `calc(100% - ${drawerWidth}px)`,
    },
    [theme.breakpoints.down("md")]: {
      marginLeft: "20px",
      width: `calc(100% - ${drawerWidth}px)`,
      padding: "16px",
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: "10px",
      width: `calc(100% - ${drawerWidth}px)`,
      padding: "16px",
      marginRight: "10px",
    },
  }),
  ...(open && {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    width: `calc(100% - ${drawerWidth}px)`,
    [theme.breakpoints.down("md")]: {
      marginLeft: "20px",
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: "10px",
    },
  }),
}));
