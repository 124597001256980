import { useCallback, useEffect, useRef, useState } from "react";

import { Logout } from "@mui/icons-material";
import {
  Avatar,
  Chip,
  ClickAwayListener,
  Divider,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Popper,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";

import { DecodedGoogleToken } from "../../../state/auth";
import { borderRadius } from "../../../styles/theme";
import Transitions from "../../common/transitions";
import MainCard from "../MainCard";

interface Props {
  decodedCredentials: DecodedGoogleToken;
  onLogout(): void;
}

export default function LoggedInUser({ decodedCredentials, onLogout }: Props) {
  const theme = useTheme();

  const [open, setOpen] = useState(false);

  const anchorRef = useRef<HTMLDivElement>(null);

  const handleClickAway = useCallback((event: MouseEvent | TouchEvent) => {
    if (!anchorRef.current?.contains(event.target as HTMLElement)) setOpen(false);
  }, []);

  const handleClickOnChip = useCallback(() => {
    setOpen((prevOpen) => !prevOpen);
  }, []);

  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current && !open) anchorRef.current?.focus();

    prevOpen.current = open;
  }, [open]);

  return (
    <>
      <Chip
        sx={{ height: "48px", alignItems: "center", borderRadius: "27px", transition: "all .2s ease-in-out" }}
        icon={
          <Avatar
            src={decodedCredentials.picture}
            sx={{ width: "34px", height: "34px", fontSize: "1.2rem", margin: "8px 0 8px 8px !important", cursor: "pointer" }}
            ref={anchorRef}
            aria-controls={open ? "menu-list-grow" : undefined}
            aria-haspopup="true"
            color="inherit"
          />
        }
        variant="outlined"
        ref={anchorRef}
        aria-controls={open ? "menu-list-grow" : undefined}
        aria-haspopup="true"
        onClick={handleClickOnChip}
        color="primary"
        label={decodedCredentials.name}
      />
      <Popper
        placement="bottom-end"
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{ modifiers: [{ name: "offset", options: { offset: [0, 4] } }] }}
      >
        {({ TransitionProps }) => (
          <Transitions in={open} {...TransitionProps}>
            <Paper>
              <ClickAwayListener onClickAway={handleClickAway}>
                <MainCard border={false} elevation={16} content={false} boxShadow shadow={theme.shadows[16]}>
                  <Stack spacing={0.5} sx={{ p: 2 }}>
                    <Stack direction="row" spacing={0.5} alignItems="center">
                      <Typography variant="h4">Olá,</Typography>
                      <Typography component="span" variant="h4" sx={{ fontWeight: 400 }}>
                        {decodedCredentials.name}
                      </Typography>
                    </Stack>
                    <Typography variant="subtitle2">Colaborador Consyst-e</Typography>
                    <Divider />
                    <List
                      component="nav"
                      sx={{
                        width: "100%",
                        maxWidth: 350,
                        minWidth: 300,
                        backgroundColor: theme.palette.background.paper,
                        borderRadius: "10px",
                        [theme.breakpoints.down("md")]: { minWidth: "100%" },
                      }}
                    >
                      <ListItemButton sx={{ borderRadius: `${borderRadius}px` }} onClick={onLogout}>
                        <ListItemIcon>
                          <Logout sx={{ stroke: 1.5, size: "1.3rem" }} />
                        </ListItemIcon>
                        <ListItemText primary={<Typography variant="body2">Logout</Typography>} />
                      </ListItemButton>
                    </List>
                  </Stack>
                </MainCard>
              </ClickAwayListener>
            </Paper>
          </Transitions>
        )}
      </Popper>
    </>
  );
}
