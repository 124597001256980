import { Suspense } from "react";

import { Menu } from "@mui/icons-material";
import { Avatar, Box, ButtonBase, CircularProgress, NoSsr } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import LogoSection from "../LogoSection";

import ProfileSection from "./ProfileSection";
import SearchSection from "./SearchSection";

export default function Header({ onLeftDrawerToggle }: { onLeftDrawerToggle(): void }) {
  const theme = useTheme();

  return (
    <NoSsr>
      {/* logo & toggler button */}
      <Box
        sx={{
          width: 228,
          display: "flex",
          [theme.breakpoints.down("md")]: {
            width: "auto",
          },
        }}
      >
        <Box component="span" sx={{ display: { xs: "none", md: "block" }, flexGrow: 1 }}>
          <LogoSection />
        </Box>
        <ButtonBase sx={{ borderRadius: "12px", overflow: "hidden" }}>
          <Avatar
            variant="rounded"
            sx={{
              cursor: "pointer",
              borderRadius: "8px",
              width: "34px",
              height: "34px",
              fontSize: "1.2rem",
              transition: "all .2s ease-in-out",
              background: theme.palette.secondary.light,
              color: theme.palette.secondary.dark,
              "&:hover": {
                background: theme.palette.secondary.dark,
                color: theme.palette.secondary.light,
              },
            }}
            onClick={onLeftDrawerToggle}
            color="inherit"
          >
            <Menu sx={{ stroke: 1.5, size: "1.3rem" }} />
          </Avatar>
        </ButtonBase>
      </Box>

      <SearchSection />

      <Box sx={{ flexGrow: 1 }} />

      <Suspense fallback={<CircularProgress />}>
        <ProfileSection />
      </Suspense>
    </NoSsr>
  );
}
