import { useState } from "react";

import { Search, Tune } from "@mui/icons-material";
import { Box, ButtonBase, Card, Grid, InputAdornment, Popper } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";

import PopupStateComponent, { bindPopper, bindToggle } from "material-ui-popup-state";

import Transitions from "../../common/transitions";

import { MobileSearch } from "./MobileSearch";
import { HeaderAvatarStyle, OutlineInputStyle } from "./common";

// styles
const PopperStyle = styled(Popper)(({ theme }) => ({
  zIndex: 1100,
  width: "99%",
  top: "-55px !important",
  padding: "0 12px",
  [theme.breakpoints.down("sm")]: {
    padding: "0 10px",
  },
}));

export default function SearchSection() {
  const theme = useTheme();
  const [value, setValue] = useState("");

  return (
    <>
      <Box sx={{ display: { xs: "block", md: "none" } }}>
        <PopupStateComponent variant="popper" popupId="demo-popup-popper">
          {(popupState) => (
            <>
              <Box sx={{ ml: 2 }}>
                <ButtonBase sx={{ borderRadius: "12px" }}>
                  <HeaderAvatarStyle variant="rounded" {...bindToggle(popupState)}>
                    <Search sx={{ stroke: 1.5, size: "1.2rem" }} />
                  </HeaderAvatarStyle>
                </ButtonBase>
              </Box>
              <PopperStyle {...bindPopper(popupState)} transition>
                {({ TransitionProps }) => (
                  <>
                    <Transitions type="zoom" {...TransitionProps} sx={{ transformOrigin: "center left" }}>
                      <Card
                        sx={{
                          background: "#fff",
                          [theme.breakpoints.down("sm")]: {
                            border: 0,
                            boxShadow: "none",
                          },
                        }}
                      >
                        <Box sx={{ p: 2 }}>
                          <Grid container alignItems="center" justifyContent="space-between">
                            <Grid item xs>
                              <MobileSearch value={value} setValue={setValue} popupState={popupState} />
                            </Grid>
                          </Grid>
                        </Box>
                      </Card>
                    </Transitions>
                  </>
                )}
              </PopperStyle>
            </>
          )}
        </PopupStateComponent>
      </Box>
      <Box sx={{ display: { xs: "none", md: "block" } }}>
        <OutlineInputStyle
          id="input-search-header"
          value={value}
          onChange={(e) => setValue(e.target.value)}
          placeholder="Search"
          startAdornment={
            <InputAdornment position="start">
              <Search sx={{ stroke: 1.5, size: "1rem", color: theme.palette.grey[500] }} />
            </InputAdornment>
          }
          endAdornment={
            <InputAdornment position="end">
              <ButtonBase sx={{ borderRadius: "12px" }}>
                <HeaderAvatarStyle variant="rounded">
                  <Tune sx={{ stroke: 1.5, size: "1.3rem" }} />
                </HeaderAvatarStyle>
              </ButtonBase>
            </InputAdornment>
          }
          aria-describedby="search-helper-text"
          inputProps={{ "aria-label": "weight" }}
        />
      </Box>
    </>
  );
}
