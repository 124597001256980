import { Typography } from "@mui/material";

import MainCard from "../layouts/MainCard";

export default function Blocked() {
  return (
    <MainCard title="Bem Vindo">
      <Typography variant="body2">Bem vindo à ferramenta de administração do GCert. Para se conectar, utilize os botões acima.</Typography>
    </MainCard>
  );
}
