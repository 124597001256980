import { useCallback } from "react";

import { CredentialResponse, GoogleLogin, googleLogout } from "@react-oauth/google";
import { useAtom, useAtomValue } from "jotai";

import { credentialsAtom, validCredentialsAtom } from "../../../state/auth";

import LoggedInUser from "./LoggedInUser";

export default function ProfileSection() {
  const [, setCredentials] = useAtom(credentialsAtom);
  const decodedCredentials = useAtomValue(validCredentialsAtom);

  const onSuccess = useCallback(
    (res: CredentialResponse) => {
      setCredentials(res.credential);
    },
    [setCredentials],
  );

  const handleLogout = useCallback(() => {
    googleLogout();
    setCredentials(undefined);
  }, [setCredentials]);

  return decodedCredentials ? (
    <LoggedInUser decodedCredentials={decodedCredentials} onLogout={handleLogout} />
  ) : (
    <GoogleLogin onSuccess={onSuccess} useOneTap locale="pt_BR" theme="filled_blue" />
  );
}
